/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, map, mergeMap, tap } from 'rxjs/operators';

import { SnackbarService } from 'services/snackbar.service';
import { RentalOfferApiFacade } from '../rental-offer-api.facade';
import { Tenant } from '../rental-offer.model';
import * as DataActions from './actions';
import { getAgentCompany } from 'src/app/common-models/mapping';
import { AuthService } from 'services/auth.service';
import { CustomerWithContact } from "@domains/customer";
import { EMPTY } from 'rxjs';
import { DocumentsDataFacade } from "../../documents";
import { TranslationService } from 'src/app/i18n/TranslationService';

@Injectable()
export class RentalOfferEffects {
  public loadRentalOffers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadRentalOffers),
      switchMap(({ objectId }) =>
        this._rentalOfferApiFacade.loadRentalOffers$(objectId).pipe(
          switchMap((value) => [DataActions.RentalOffersLoaded({ payload: value })]),
          catchError(() => [DataActions.RentalOffersLoadingFailed()])
        )
      )
    )
  );

  public loadRentalOffersByCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadRentalOffersByCustomer),
      switchMap(({ customerId }) =>
        this._rentalOfferApiFacade.loadRentalOffersByCustomer$(customerId).pipe(
          switchMap((value) => [DataActions.RentalOffersLoaded({ payload: value })]),
          catchError(() => [DataActions.RentalOffersLoadingFailed()])
        )
      )
    )
  );

  public loadRentalOfferDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadRentalOfferDetails),
      switchMap(({ objectId, rentalOfferId }) =>
        this._rentalOfferApiFacade.loadRentalOfferDetails$(objectId, rentalOfferId).pipe(
          switchMap((value) => [DataActions.RentalOfferDetailsLoaded({ payload: value })]),
          catchError(() => [DataActions.RentalOfferDetailsLoadingFailed()])
        )
      )
    )
  );

  public sendTenantCustomerPortalNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendTenantCustomerPortalNotification),
      switchMap(({ objectId, rentalOfferId }) =>
        this._rentalOfferApiFacade.sendTenantCustomerPortalNotification$(rentalOfferId).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Nachricht erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          switchMap(() => [DataActions.LoadRentalOffers({ objectId })]),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Nachricht konnte nicht gesendet werden',
              'mat-warn',
              true
            );

            return [];
          }),
        )
      )
    )
  );

  public saveRentalOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveRentalOffer),
      switchMap(({ rentalOffer, isFinal, ignoreStateCheck }) => {
        const tenantsWithoutCustomers = rentalOffer.tenants.map(
          (tenant: Tenant) => {
            const {
              id,
              title,
              firstname,
              lastname,
              birthdate,
              contact,
              nationality,
              // @ts-ignore
              signature,
              ...tenantWithoutCustomer
            } = tenant;

            return {
              ...tenantWithoutCustomer,
              customerId: (tenant as any).customerId,
            };
          }
        );

        const landlordsWithoutCustomers = rentalOffer.landlords.map(
          (landlord: CustomerWithContact) => {
            const {
              id,
              title,
              firstname,
              lastname,
              birthdate,
              contact,
              nationality,
              pep,
              ...landlordWithoutCustomer
            } = landlord;
            return {
              ...landlordWithoutCustomer,
              customerId: (landlord as any).customerId,
            };
          }
        );

        const {
          signatureData,
          ...rentalOfferWithoutSignature
        } = rentalOffer;

        // @ts-ignore
        const { customerData, ...businessTenant } = rentalOffer.businessTenant ?? {};

        const rentalOfferWithoutCustomers = {
          ...rentalOfferWithoutSignature,
          tenants: tenantsWithoutCustomers,
          landlords: landlordsWithoutCustomers,
          businessTenant,
        };

        return this._rentalOfferApiFacade.saveRentalOffer$(rentalOfferWithoutCustomers, ignoreStateCheck).pipe(
          switchMap((ro) => {
            if (isFinal) {
              this._router.navigateByUrl(`/objects/${ro.objectId}/digital-offerings`);

              return [DataActions.SaveRentalOfferSuccess(), DataActions.CreateRentalOfferSucceded()];
            }
            return [DataActions.SaveRentalOfferSuccess()];
          }),
          catchError(() => [DataActions.SaveRentalOfferFailed()]),
        );
      })
    ),
  );

  public saveRentalOfferFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveRentalOfferFailed),
      map(() =>
        this._snackbarService.showSnackbar(
          `${this._translationService.instant('rental_offer')} konnte nicht gespeichert werden`,
          'mat-warn',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public updateRentalOfferCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdateRentalOfferCustomers),
      switchMap(({ objectId, rentalOfferId, tenants, landlords }) =>
        this._rentalOfferApiFacade.loadRentalOfferDetails$(objectId, rentalOfferId).pipe(
          switchMap((rentalOffer) => {
            const updatedTenants = rentalOffer.tenants.map((t, index) => ({
              ...t,
              customerId: tenants[index]?.id,
            }));
            rentalOffer.tenants = updatedTenants;

            const updatedLandlords = rentalOffer.landlords.map((s, index) => ({
              ...s,
              customerId: landlords[index]?.id,
            }));
            rentalOffer.landlords = updatedLandlords;

            return [DataActions.SaveRentalOffer({ rentalOffer, isFinal: true })];
          })
        )
      ),
    )
  );

  public createAndUploadRentalOfferDocumentFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateAndUploadRentalOfferDocumentFailed),
      map(() =>
        this._snackbarService.showSnackbar(
          `${this._translationService.instant('rental_offer')} PDF konnte nicht erzeugt werden`,
          'mat-warn',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public rentalOfferCreationSucceded$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateRentalOfferSucceded),
      map(() =>
        this._snackbarService.showSnackbar(
          `${this._translationService.instant('rental_offer')} erfolgreich erstellt`,
          'mat-primary',
          true
        )
      )
    ),
    { dispatch: false }
  );

  deleteRentalOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteRentalOffer),
      mergeMap(({ rentalOfferId, objectId }) =>
        this._rentalOfferApiFacade.deleteRentalOffer$(rentalOfferId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('rental_offer')} erfolgreich gelöscht`,
              'mat-primary',
              true
            );
            this._documentDataFacade.deleteRentalOffer(rentalOfferId)
            return [
              DataActions.DeleteRentalOfferSuccess({
                rentalOfferId,
              }),
            ];
          }), catchError(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('rental_offer')} konnte nicht gelöscht werden`,
              'mat-warn',
              true
            );
            return [DataActions.DeleteRentalOfferFailed({ rentalOfferId })];
          })
        )
      ),
    ),
  );

  public updateRentalOfferMasterTemplate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdateRentalOfferMasterTemplate),
      mergeMap(({ rentalOfferId, objectId }) =>
        this._rentalOfferApiFacade.updateRentalOfferMasterTemplate$(rentalOfferId, objectId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              'Muster erfolgreich aktualisiert',
              'mat-primary',
              true
            );
            return [DataActions.LoadRentalOffers({ objectId })];
          }), catchError(() => {
            this._snackbarService.showSnackbar(
              'Muster konnte nicht aktualisiert werden',
              'mat-warn',
              true
            );
            return [];
          })
        )
      ),
    ),
  );

  public resetRentalOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.ResetRentalOffer),
      mergeMap(({ objectId, rentalOfferId }) =>
        this._rentalOfferApiFacade.resetRentalOffer$(objectId, rentalOfferId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('rental_offer')} erfolgreich aktualisiert`,
              'mat-primary',
              true
            );
            return [DataActions.LoadRentalOffers({ objectId })];
          }), catchError(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('rental_offer')} konnte nicht aktualisiert werden`,
              'mat-warn',
              true
            );
            return [];
          })
        )
      ),
    ),
  );

  public trySignAndCompletRentalOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SignAndCompleteRentalOffer),
      mergeMap(({ rentalOfferSignature }) =>
        this._rentalOfferApiFacade.signAndCompleteRentalOffer$(
          rentalOfferSignature.objectId,
          rentalOfferSignature.rentalOfferId,
          rentalOfferSignature.tenantsSignatureData,
          rentalOfferSignature.landlordSignatureData,
          rentalOfferSignature.landlordAcceptance,
          getAgentCompany(this._authService)
        ).pipe(
          tap((rentalOffer) => {
            this._router.navigateByUrl(
              `/objects/${rentalOffer.objectId}/digital-offerings/rental-offer/${rentalOffer.id}/email`
            );
          }),
          catchError(() => {
            this._router.navigateByUrl(`/objects/${rentalOfferSignature.objectId}/digital-offerings/rental-offer`);

            return EMPTY;
          }),
        )),
    ),
    { dispatch: false }
  );

  public draftSignRentalOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DraftSignRentalOffer),
      mergeMap(({ rentalOfferSignature, openEmailDialog }) =>
        this._rentalOfferApiFacade.signAndCompleteRentalOffer$(
          rentalOfferSignature.objectId,
          rentalOfferSignature.rentalOfferId,
          rentalOfferSignature.tenantsSignatureData,
          rentalOfferSignature.landlordSignatureData,
          rentalOfferSignature.landlordAcceptance,
          getAgentCompany(this._authService)
        ).pipe(
          tap((rentalOffer) => {
            if (openEmailDialog) {
              this._router.navigateByUrl(
                `/objects/${rentalOffer.objectId}/digital-offerings/rental-offer/${rentalOffer.id}/email`
              );
            }
          }),
          catchError(() => {
            this._router.navigateByUrl(`/objects/${rentalOfferSignature.objectId}/digital-offerings/rental-offer`);

            return EMPTY;
          }),
        )),
    ),
    { dispatch: false }
  );

  public sendRentalOfferEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendRentalOfferEmail),
      switchMap(({ rentalOfferId, emailData, agentCompany }) =>
        this._rentalOfferApiFacade.sendRentalOfferEmail$(
          rentalOfferId,
          emailData,
          agentCompany
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('rental_offer')} E-Mail erfolgreich gesendet`,
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('rental_offer')} E-Mail konnte nicht gesendet werden`,
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  public sendCustomerPortalLink$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendCustomerPortalLink),
      switchMap(({ rentalOfferId, emailData }) =>
        this._rentalOfferApiFacade.sendCustomerPortalLinkEmail$(
          rentalOfferId,
          emailData,
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Interessentenportal E-Mail erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Interessentenportal E-Mail konnte nicht gesendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  public sendLandlordCustomerPortalLink$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendLandlordCustomerPortalLink),
      switchMap(({ rentalOfferId, emailData }) =>
        this._rentalOfferApiFacade.sendLandlordCustomerPortalLink$(
          rentalOfferId,
          emailData,
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Eigentümerportal E-Mail erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Eigentümerportal E-Mail konnte nicht gesendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _snackbarService: SnackbarService,
    private readonly _rentalOfferApiFacade: RentalOfferApiFacade,
    private readonly _documentDataFacade: DocumentsDataFacade,
    private readonly _translationService: TranslationService,
  ) { }
}
