import { Injectable } from "@angular/core";
import { ActionsSubject, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, first, tap } from 'rxjs/operators';
import { BuyingOfferApiFacade } from "./buying-offer-api.facade";
import { BuyingOffer, BuyingOfferSignatureCreation } from "./buying-offer.model";
import { BuyingOfferActions } from "./store/action-types";
import { BuyingOfferSelectors, BuyingOfferFeatureState } from "./store";
import { AuthService } from "services/auth.service";
import { DocumentService } from "services/document.service";
import { getAgentCompany } from "src/app/common-models/mapping";
import { CustomerWithContact } from "@domains/customer";
import { ofType } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class BuyingOfferDataFacade {
  buyingOffers$ = this._store$.select(BuyingOfferSelectors.buyingOffers);
  buyingOffersLoading$ = this._store$.select(BuyingOfferSelectors.buyingOffersLoading);
  buyingOfferDetails$ = this._store$.select(BuyingOfferSelectors.buyingOfferDetails);
  buyingOfferDetailsLoading$ = this._store$.select(BuyingOfferSelectors.buyingOfferDetailsLoading);

  constructor(
    private readonly _authService: AuthService,
    private readonly _docService: DocumentService,
    private readonly _buyingOfferApiFacade: BuyingOfferApiFacade,
    private readonly _store$: Store<BuyingOfferFeatureState>,
    private readonly actionsListener$: ActionsSubject,
  ) { }

  public loadBuyingOffers(objectId: string): void {
    this._store$.dispatch(BuyingOfferActions.LoadBuyingOffers({ objectId }));
  }

  public loadBuyingOffersByCustomer(customerId: string): void {
    this._store$.dispatch(BuyingOfferActions.LoadBuyingOffersByCustomer({ customerId }));
  }

  public loadBuyingOfferDetails(objectId: string, buyingOfferId: string): void {
    this._store$.dispatch(BuyingOfferActions.LoadBuyingOfferDetails({ objectId, buyingOfferId }));
  }

  public sendBuyerCustomerPortalNotification(objectId: string, buyingOfferId: string): void {
    this._store$.dispatch(BuyingOfferActions.SendBuyerCustomerPortalNotification({ objectId, buyingOfferId }));
  }

  public sendSellerOwnerPortalNotification(objectId: string, buyingOfferId: string): void {
    this._store$.dispatch(BuyingOfferActions.SendSellerOwnerPortalNotification({ objectId, buyingOfferId }));
  }

  public saveBuyingOffer(buyingOffer: any, isFinal = false, ignoreStateCheck = false): void {
    this._store$.dispatch(BuyingOfferActions.SaveBuyingOffer({ buyingOffer, isFinal, ignoreStateCheck }));
  }

  public sendBuyingOfferEmail(buyingOfferId: string, emailData: any): void {
    const agentCompany = getAgentCompany(this._authService);
    this._store$.dispatch(BuyingOfferActions.SendBuyingOfferEmail({
      buyingOfferId,
      emailData,
      agentCompany,
    }));
  }

  public sendCustomerPortalLink(buyingOfferId: string, emailData: any): void {
    this._store$.dispatch(BuyingOfferActions.SendCustomerPortalLink({ buyingOfferId, emailData }));
  }

  public sendSellerCustomerPortalLinkEmail(buyingOfferId: string, emailData: any): void {
    this._store$.dispatch(BuyingOfferActions.SendSellerCustomerPortalLink({ buyingOfferId, emailData }));
  }

  public createBuyingOffer$(buyingOffer: any): Observable<BuyingOffer> {
    return this._buyingOfferApiFacade.saveBuyingOffer$(buyingOffer)
  }

  public getBuyingOfferDetails$(objectId: string, buyingOfferId: string): Observable<BuyingOffer> {
    return this._buyingOfferApiFacade.loadBuyingOfferDetails$(objectId, buyingOfferId);
  }

  public updateOwnerAvailability$(objectId: string, data: any): Observable<any> {
    return this._buyingOfferApiFacade.updateBuyingOffersAvailability$(objectId, data);
  }

  public getBuyingOfferMasterTemplate$(objectId: string): Observable<BuyingOffer> {
    return this._buyingOfferApiFacade.getBuyingOfferMasterTemplate$(objectId);
  }

  public getBuyingOfferEmailVariables$(buyingOfferId: string, documentUrl: string): Observable<any> {
    return this._buyingOfferApiFacade.getBuyingOfferEmailVariables$(buyingOfferId, documentUrl);
  }

  public getBuyingOfferCustomerPortalLinkEmailVariables$(buyingOfferId: string, handInOffer: boolean, customers: any[]): Observable<any> {
    return this._buyingOfferApiFacade.getBuyingOfferCustomerPortalLinkEmailVariables$(buyingOfferId, handInOffer, customers);
  }

  public getBuyingOfferOwnerPortalLinkEmailVariables$(buyingOfferId: string, customers: any[]): Observable<any> {
    return this._buyingOfferApiFacade.getBuyingOfferOwnerPortalLinkEmailVariables$(buyingOfferId, customers);
  }

  public deleteBuyingOffer(buyingOfferId: string, objectId: string): void {
    this._store$.dispatch(BuyingOfferActions.DeleteBuyingOffer({ buyingOfferId, objectId }));
  }

  public updateBuyingOfferMasterTemplate(buyingOfferId: string, objectId: string): void {
    this._store$.dispatch(BuyingOfferActions.UpdateBuyingOfferMasterTemplate({ buyingOfferId, objectId }));
  }

  public resetBuyingOffer(objectId: string, buyingOfferId: string): void {
    this._store$.dispatch(BuyingOfferActions.ResetBuyingOffer({ objectId, buyingOfferId }));
  }

  public signAndCompleteBuyingOffer(buyingOfferSignature: BuyingOfferSignatureCreation): void {
    this._store$.dispatch(BuyingOfferActions.SignAndCompleteBuyingOffer({ buyingOfferSignature }));
  }

  public draftSignBuyingOffer(buyingOfferSignature: BuyingOfferSignatureCreation, openEmailDialog = true): void {
    this._store$.dispatch(BuyingOfferActions.DraftSignBuyingOffer({ buyingOfferSignature, openEmailDialog }));
  }

  public updateBuyingOfferCustomers(
    sellers: CustomerWithContact[],
    buyers: CustomerWithContact[],
    buyingOfferId: string,
    objectId: string
  ): void {
    this._store$.dispatch(BuyingOfferActions.UpdateBuyingOfferCustomers({
      sellers,
      buyers,
      buyingOfferId,
      objectId,
    }));
  }

  public createBuyingOfferDocument$(objectId: string, buyingOfferId: string): Observable<string> {
    const agentCompany = getAgentCompany(this._authService);
    return this._buyingOfferApiFacade.createBuyingOfferDocument$(objectId, buyingOfferId, agentCompany);
  }

  public failedCreateAndUploadBuyingOfferDocument(): void {
    this._store$.dispatch(BuyingOfferActions.CreateAndUploadBuyingOfferDocumentFailed());
  }

  public compressFiles$(objectId: string, buyingOfferId: string, filePaths: string[], fileNames: string[]): Observable<string> {
    return this._buyingOfferApiFacade.compressFiles$(objectId, buyingOfferId, filePaths, fileNames);
  }

  public showBuyingOfferDocument(objectId: string, buyingOfferId: string): void {
    this.createBuyingOfferDocument$(objectId, buyingOfferId).pipe(
      first(),
      tap((url) => {
        this._docService.openDocumentLink(url);
      }),
      catchError((err) => {
        this.failedCreateAndUploadBuyingOfferDocument();
        throw err;
      })
    ).subscribe();
  }

  public createAdminBuyingOfferDocument$(objectId: string, buyingOfferId: string): Observable<string> {
    const agentCompany = getAgentCompany(this._authService);
    return this._buyingOfferApiFacade.createAdminBuyingOffer$(objectId, buyingOfferId, agentCompany);
  }

  //Admin Buying Offer To Recreate
  public createAdminBuyingOffer(objectId: string, buyingOfferId: string): void {
    this.createAdminBuyingOfferDocument$(objectId, buyingOfferId).pipe(
      first(),
      tap((url) => {
        console.log("New Buying Offer Document Created by Admin", url);
      }),
      catchError((err) => {
        this.failedCreateAndUploadBuyingOfferDocument();
        throw err;
      })
    ).subscribe();
  }

  public orderBuyingContract(buyingOfferId: string, objectId: string): void {
    this._store$.dispatch(BuyingOfferActions.OrderBuyingContract({ buyingOfferId, objectId }));
  }

  public copyBuyingOfferToAnotherProperty(buyingOfferId: string, destinationPropertyId: string): void {
    this._store$.dispatch(BuyingOfferActions.CopyOfferToProperty({ buyingOfferId, destinationPropertyId }));
  }

  public buyingOfferSaved$() {
    return this.actionsListener$.pipe(
      ofType(BuyingOfferActions.SaveBuyingOfferSuccess)
    );
  }
}
