import { ActionsSubject, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { viewingPassSelectors, ViewingPassFeatureState } from './store';
import { ViewingPassActions } from './store/action-types';
import { ViewingPassApiFacade } from './viewing-pass-api.facade';
import { from, Observable, of } from 'rxjs';
import { DocumentService } from 'services/document.service';
import { getAgentCompany } from 'src/app/common-models/mapping';
import { AuthService } from 'services/auth.service';
import { catchError, concatMap, first, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import {
  ViewingPassCreation,
  ViewingPassSignatureCreation,
  ViewingPass,
} from './viewing-pass.model';
import { ofType } from "@ngrx/effects";
import { ViewingPassOfflineService } from 'src/app/domains/viewing-pass/vp-offline.service';
import { BusinessCustomerDataFacade } from '@domains/business-customer';
import { CustomerDataFacade } from '@domains/customer';

@Injectable({ providedIn: 'root' })
export class ViewingPassDataFacade {
  viewingPasses$ = this._store$.select(viewingPassSelectors.viewingPasses);
  viewingPassesLoading$ = this._store$.select(viewingPassSelectors.viewingPassesLoading);
  viewingPassDetails$ = this._store$.select(viewingPassSelectors.viewingPassDetails);
  viewingPassDetailsLoading$ = this._store$.select(viewingPassSelectors.viewingPassDetailsLoading);
  savingProcessLoading$ = this._store$.select(viewingPassSelectors.savingProcessLoading);
  savingProcessHasError$ = this._store$.select(viewingPassSelectors.savingProcessHasError);

  constructor(
    private readonly _store$: Store<ViewingPassFeatureState>,
    private readonly _viewingPassApiFacade: ViewingPassApiFacade,
    private readonly _viewingPassOfflineService: ViewingPassOfflineService,
    private readonly _customerDataFacade: CustomerDataFacade,
    private readonly _businessCustomerDataFacade: BusinessCustomerDataFacade,
    private readonly _authService: AuthService,
    private readonly _docService: DocumentService,
    private readonly actionsListener$: ActionsSubject,
  ) { }

  public loadViewingPasses(objectId: string): void {
    this._store$.dispatch(ViewingPassActions.LoadViewingPasses({ objectId }));
  }

  public loadViewingPassesByCustomer(customerId: string): void {
    this._store$.dispatch(ViewingPassActions.LoadViewingPassesByCustomer({ customerId }));
  }

  public loadViewingPassDetails(objectId: string, viewingPassId: string): void {
    this._store$.dispatch(ViewingPassActions.LoadViewingPassDetails({ objectId, viewingPassId }));
  }

  public deleteViewingPass(viewingPassId: string): void {
    this._store$.dispatch(ViewingPassActions.DeleteViewingPass({ viewingPassId }));
  }

  public saveViewingPass(viewingPass: ViewingPassCreation, isFinal: boolean = false, shouldPrint: boolean = false): void {
    this._store$.dispatch(ViewingPassActions.SaveViewingPass({ viewingPass, isFinal, shouldPrint }));
  }

  public sendViewingPassEmail(viewingPassId: string, emailData: any): void {
    const agentCompany = getAgentCompany(this._authService);
    const emailPayload = { viewingPassId, emailData, agentCompany };
    this._store$.dispatch(ViewingPassActions.SendViewingPassEmail(emailPayload));
  }
  public sendCustomerPortalLinkEmail(viewingPassId: string, emailData: any): void {
    this._store$.dispatch(ViewingPassActions.SendCustomerPortalLink({ viewingPassId, emailData }));
  }

  public signAndCompleteViewingPass(viewingPassSignature: ViewingPassSignatureCreation): void {
    this._store$.dispatch(ViewingPassActions.SignAndCompleteViewingPass({ viewingPassSignature }));
  }

  public failedCreateAndUploadViewingPassDocument(): void {
    this._store$.dispatch(ViewingPassActions.CreateAndUploadViewingPassDocumentFailed());
  }

  public createViewingPass$(viewingPass: ViewingPassCreation): Observable<ViewingPass> {
    return this._viewingPassApiFacade.saveViewingPass$(viewingPass);
  }

  public getLastViewingPassDetails$(objectId: string): Observable<ViewingPass> {
    return this._viewingPassApiFacade.getLastViewingPassDetails$(objectId);
  }

  public getSignedViewingPassesAmount$(objectId: string): Observable<{ amount: number }> {
    return this._viewingPassApiFacade.getSignedViewingPassesAmount$(objectId);
  }

  public downloadSignedViewingPassesDocs$(objectId: string): Observable<string> {
    return this._viewingPassApiFacade.getSignedViewingPassesDocsUrl$(objectId).pipe(
      map(data => data.storageUrl),
      tap((storageUrl) => {
        if (storageUrl) {
          const lastIndex = storageUrl.lastIndexOf('/');
          const a = document.createElement('a');
          a.download = storageUrl.substring(lastIndex + 1);
          a.href = storageUrl;
          document.body.appendChild(a);
          a.click();
        }
      })
    );
  }

  public getViewingPassEmailVariables$(viewingPassId: string): Observable<any> {
    const agentCompany = getAgentCompany(this._authService);
    return this._viewingPassApiFacade.getViewingPassEmailVariables$(viewingPassId, agentCompany);
  }

  public getViewingPassDetails$(objectId: string, viewingPassId: string): Observable<ViewingPass> {
    return this._viewingPassApiFacade.loadViewingPassDetails$(objectId, viewingPassId);
  }

  public createViewingPassDocument$(objectId: string, viewingPassId: string): Observable<string> {
    const agentCompany = getAgentCompany(this._authService);
    return this._viewingPassApiFacade.createViewingPassDocument$(objectId, viewingPassId, agentCompany);
  }

  public compressFiles$(objectId: string, viewingPassId: string, filePaths: string[], fileNames: string[]): Observable<string> {
    return this._viewingPassApiFacade.compressFiles$(objectId, viewingPassId, filePaths, fileNames);
  }

  public showViewingPassDocument(objectId: string, viewingPassId: string): void {
    this.createViewingPassDocument$(objectId, viewingPassId).pipe(
      first(),
      tap((url) => {
        this._docService.openDocumentLink(url);
      }),
      catchError((err) => {
        this.failedCreateAndUploadViewingPassDocument();
        throw err;
      }),
    ).subscribe();
  }

  saveViewingPassOffline$(viewingPass: any): Observable<void> {
    return this._viewingPassOfflineService.save$(viewingPass);
  }

  public saveViewingPassSucceeded$() {
    return this.actionsListener$.pipe(
      ofType(ViewingPassActions.SaveViewingPassSucceded)
    );
  }

  viewingPassesLoadingFinished$() {
    return this.actionsListener$.pipe(
      ofType(ViewingPassActions.ViewingPassesLoaded)
    );
  }

  public getViewingPassCustomerPortalLinkEmailVariables$(
    viewingPassId: string,
    handInPass: boolean,
    customers: any[]
  ): Observable<any> {
    return this._viewingPassApiFacade.getViewingPassCustomerPortalLinkEmailVariables$(
      viewingPassId,
      handInPass,
      customers,
    );
  }

  syncAllLocalViewingPasses$(): Observable<(string | null)[]> {
    return this._viewingPassOfflineService.getAll$().pipe(
      first(),
      switchMap((viewingPasses) => {
        if (!viewingPasses || viewingPasses.length === 0) {
          return of([]);
        }

        return from(viewingPasses).pipe(
          concatMap((viewingPass) => this._syncLocalViewingPass$(viewingPass.id).pipe(
            catchError((error) => {
              console.error(`Failed to sync viewing pass with ID ${viewingPass.id}:`, error);
              return of(null);
            })
          )),
          toArray(),
        );
      }),
      catchError((error) => {
        console.error('Failed to retrieve offline viewing passes:', error);
        return of([]);
      })
    );
  }

  private _syncLocalViewingPass$(viewingPassId: string): Observable<string | null> {
    return this._viewingPassOfflineService.get$(viewingPassId).pipe(
      switchMap((viewingPass: any) => {
        if (!viewingPass) {
          return of(null);
        }

        const syncCustomer$: Observable<string | null | string[]> = viewingPass.customerType === 'business'
          ? this._businessCustomerDataFacade.syncLocalBusinessCustomer$(viewingPass.businessCustomer)
          : this._customerDataFacade.syncLocalCustomers$(viewingPass.customers);

        return syncCustomer$.pipe(
          switchMap((updatedCustomerIds: string | string[] | null) => {

            if (viewingPass.customerType === 'business') {
              viewingPass.businessCustomer = updatedCustomerIds as string || '';
            } else {
              viewingPass.customers = updatedCustomerIds as string[];
            }

            return this.createViewingPass$(viewingPass).pipe(
              switchMap((createdViewingPass) =>
                this._viewingPassOfflineService.delete$(viewingPassId).pipe(
                  map(() => createdViewingPass.id as string)
                )
              ),
              catchError((error) => {
                console.error('Failed to create or delete viewing pass:', error);
                return of(null);
              })
            );
          }),
          catchError((error) => {
            console.error('Failed to sync customers:', error);
            return of(null);
          })
        );
      }),
      catchError((error) => {
        console.error('Failed to retrieve viewing pass:', error);
        return of(null);
      })
    );
  }
}
